import React, { useState, useEffect } from 'react'
import { Button, Container } from 'reactstrap'
import Cookies from 'js-cookie'

import './CookieConsentNotification.scss'


export default () => {
  const [ show, setShow ] = useState(false)

  const setCookie = () => {
    Cookies.set('cookie-consent', 1)
    setShow(false)
  }

  useEffect(() => {
    const cookieConsent = Cookies.get('cookie-consent')
    if (!cookieConsent)
      setShow(true)
  }, [])


  if (!show) return null

  return (
    <div className="CookieConsentNotification">
      <Container fluid>
        <div className="d-md-flex">
          <div className="my-md-auto ml-md-auto mr-md-4">
            Our website uses cookies so we can analyse our site and give you the
            best experience. View our <a href="https://app.myhr.works/privacy">privacy policy</a> for more information.
          </div>
          <Button
          color="cyan"
          onClick={setCookie}
          className="mt-2 mt-md-0 mr-md-auto"
          >
            I accept
          </Button>

        </div>
      </Container>
    </div>
  )
}