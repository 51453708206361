import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Cookies from 'js-cookie'

import Navbar from './Navbar'
import Footer from './Footer'
import CookieConsentNotification from './CookieConsentNotification'

import LocaleContext, { getLocale } from './LocaleContext'


const Layout = ({
  locale,
  children,
  className = '',
  title = null,
  description = null,
  image = null,
  alternateLinks = [],
  navbar = true,
  footer = true,
  isHomePage = false,
  noIndex = false,
}) => {
  const [localeContext, setLocaleContext] = useState(getLocale(locale))

  useEffect(() => {
    // If there is a locale defined on build, save on a cookie on load page
    if (locale) {
      Cookies.remove('lang')
      Cookies.set('lang', localeContext.value)
    }

    // If there is no locale on build, check if there is already a cookie
    // Without a cookie, set en-NZ as default
    else {
      const cookieLocale = Cookies.get('lang')
      if (cookieLocale) {
        setLocaleContext(getLocale(cookieLocale))
      } else {
        setLocaleContext(getLocale())
      }
    }
  }, [])

  const countryCode = localeContext.countryCode ? localeContext.countryCode : ''
  const titleTemplate = isHomePage ? '%s' : `%s | MyHR ${countryCode}`
  const meta = []
  if (image) {
    meta.push({
      name: 'image',
      property: 'og:image',
      content: image,
    })
  }

  return (
    <div className={classnames('Main', className)}>
      {locale && <Helmet htmlAttributes={{ lang: locale }} />}
      <LocaleContext.Provider value={localeContext}>
        <Helmet
          title={title ? title : null}
          titleTemplate={titleTemplate}
          meta={meta}
          
        >
          <meta property="og:site_name" content="MyHR" />
          {description &&
            <meta name="description" content={description}/>
          }
          {noIndex && 
            <meta name="robots" content="noindex, nofollow" />
          }
          {alternateLinks &&
            alternateLinks.map(({ hrefLang, path }) => (
              <link
                rel="alternate"
                href={`https://www.myhr.works${path}`}
                hrefLang={hrefLang}
                key={hrefLang}
              />
            ))}
        </Helmet>

        {navbar && <Navbar />}

        {children}

        {footer && (
          <>
            <Footer/>
            <CookieConsentNotification />
          </>
        )}

      </LocaleContext.Provider>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export default Layout
