import React from 'react'
import { Container } from 'reactstrap'

import './Section.scss'


const Section  = ({
  children,
  className='',
  ...props
}) =>
  <section {...props} className={`Section ${className}`} >
    {children}
  </section>  


Section.Intro = ({
  children,
  className,
  ...props
}) => 
  <div
    className={'Section__intro' + (className ? ` ${className}` : '')}
    {...props}
  >
    <Container>
      {children}
    </Container>
  </div>


export default Section