import otherFeatures1 from '../images/navbar2023/Platform/MyHR_Icons_HR-Compliance-and-Advisory.png'
import otherFeatures2 from '../images/navbar2023/Platform/MyHR_Icons_Hiring-and-Onboarding.png'
import otherFeatures3 from '../images/navbar2023/Platform/MyHR_Icons_Admin-and-Reporting.png'
import otherFeatures4 from '../images/navbar2023/Platform/MyHR_Icons_Leave-and-Payroll.png'
import otherFeatures5 from '../images/navbar2023/Platform/MyHR_Icons_Performance-and-Development.png'

import resources1 from '../images/navbar2023/Resources/MyHR_Icons_Guides.png'
import resources2 from '../images/navbar2023/Resources/MyHR_Icons_Blogs.png'
import resources3 from '../images/navbar2023/Resources/MyHR_Icons_White-Papers.png'
import resources4 from '../images/navbar2023/Resources/MyHR_Icons_Webinars.png'
import resources5 from '../images/navbar2023/Resources/MyHR_Icons_FAQs.png'

import company1 from '../images/navbar2023/Company/MyHR_Icons_Contact.png'
import company2 from '../images/navbar2023/Company/MyHR_Icons_Careers.png'
import company3 from '../images/navbar2023/Company/MyHR_Icons_Partners.png'
import company4 from '../images/navbar2023/Company/MyHR_Icons_Our-Story.png'
import company5 from '../images/navbar2023/Company/MyHR_Icons_In-The-News.png'

const navigation = [
  {
    label: 'Platform',
    //subLabel: 'Platform Overview',
    //desc: "Our combination of easy-to-use HR software backed by an expert team of HR Advisors provides a wraparound HR service that's built for your business, accessible whenever and wherever you need it.",
    //href: '/platform',
    //localePrefix: true,
    //buttonLabel: 'Learn More',
    children: [
      {
        label: 'Hiring & Onboarding',
        href: '/platform/hiring-and-onboarding',
        localePrefix: true,
        desc: 'Hire talent fast and set them up for success.',
        image: otherFeatures2,
      },
      {
        label: 'HR Compliance & HR Advisory',
        href: '/platform/hr-compliance-and-advisory',
        localePrefix: true,
        desc: 'Get an HR expert backing you through any process.',
        //descAu: "Ensure your employment documents and company policies are up to date and fully compliant with Fair Work Australia.",
        image: otherFeatures1,
      },
      {
        label: 'Admin & Reporting',
        href: '/platform/admin-and-reporting',
        localePrefix: true,
        desc: 'Benchmark, manage and track your people.',
        image: otherFeatures3,
      },
      {
        label: 'Performance & Development',
        href: '/platform/performance-review-development',
        localePrefix: true,
        desc: 'Build an engaged and productive team.',
        image: otherFeatures5,
      },
      {
        label: 'Leave & Payroll',
        href: '/platform/leave-and-payroll',
        localePrefix: true,
        desc: 'Streamline leave and payroll management.',
        image: otherFeatures4,
      },
    ],
  },
  {
    label: 'Pricing',
    //subLabel: 'Join MyHR',
    //desc: "Smart HR software. Expert HR support. Start your free trial now.",
    //href: '/trial',
    //localePrefix: true,
    //buttonLabel: 'Get Started',
    children: [
      {
        label: 'Platform Pricing',
        href: '/pricing',
        localePrefix: true,
        desc:
          'Our comprehensive membership plan is all-inclusive with no additional or hidden costs. For most SMB, it’s all the HR they’ll ever need.',
      },
      {
        label: 'Recruit Shop',
        href: '/recruit-shop',
        localePrefix: true,
        desc:
          'Exclusive to MyHR members. On-the-ground help from a dedicated specialist, for complex issues that require additional expertise.',
      },
    ],
  },
  {
    label: 'Customer Stories',
    children: [
      {
        label: 'Customer Stories',
        href: '/customer-stories',
        localePrefix: true,
      },
    ],
  },
  /*
  {
    label: 'Why',
    children: [
      {
        label: 'Why MyHR?',
        href: '/why',
        localePrefix: true,
        desc: "A virtual HR department, whenever and wherever you need it. We believe every business should have access to quality, affordable HR."
      },
      {
        label: 'Customer Stories',
        href: '/customer-stories',
        localePrefix: true,
        desc: "Innovative companies across New Zealand and Australia trust MyHR to provide complete, outsourced HR."
      },

      {
        label: 'Integrations',
        href: '/integrations',
        localePrefix: true,
        desc: "Time saving integrations with leading software applications so you can create a powerful HR ecosystem to manage payroll, applicant tracking, recruitment and employee learning."
      },
    ],
  },
  */
  {
    label: 'Resources',
    children: [
      /*
      {
        label: 'All Resources',
        href: '/resources/#all',
        localePrefix: true,
      },
      */
      {
        label: 'Blog',
        href: '/resources/#blogs',
        localePrefix: true,
        desc: 'Topical HR articles, useful tips and product updates',
        image: resources2,
      },
      {
        label: 'Guides',
        href: '/resources/#guides',
        localePrefix: true,
        desc: 'Downloadable, expert HR advice and market reports',
        image: resources1,
      },
      {
        label: 'White Papers',
        href: '/resources/#whitepapers',
        localePrefix: true,
        desc: 'Deep dive articles into important HR topics',
        image: resources3,
      },
      {
        label: 'Webinars',
        href: '/resources/#live',
        localePrefix: true,
        desc: 'In depth discussions with leading HR experts',
        image: resources4,
      },
      {
        label: 'FAQs',
        href: '/resources/#faqs',
        localePrefix: true,
        desc: 'Answers to common customer questions.',
        image: resources5,
      },
    ],
  },
  {
    label: 'Company',
    children: [
      {
        label: 'Contact',
        href: '/contact',
        localePrefix: true,
        desc: '',
        image: company1,
      },
      {
        label: 'Careers',
        href: '/careers',
        localePrefix: true,
        desc: '',
        image: company2,
      },
      {
        label: 'Integrations',
        href: '/integrations',
        localePrefix: true,
        desc: '',
        image: company3,
      },
      {
        label: 'Partners',
        href: '/partners',
        localePrefix: true,
        desc: '',
        image: company3,
        excludeNavbar: true,
      },
      {
        label: 'Our Story',
        href: '/about',
        localePrefix: true,
        desc: '',
        image: company4,
      },
      /*
      {
        label: 'Executive Team',
        href: '/our-team',
        localePrefix: true,
        desc: "Our team is one of the most experienced in Australasia, having worked in businesses big and small around the globe."
      },
      */
      {
        label: 'In the News',
        href: '/news',
        localePrefix: true,
        desc: '',
        image: company5,
      },
    ],
  },
]

export default {
  'en-NZ': navigation,
  'en-AU': navigation,
}
