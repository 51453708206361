import React, { useEffect, useContext, useState } from 'react'
import { graphql } from 'gatsby'
import { Container } from 'reactstrap'
import { Parallax } from 'react-scroll-parallax'

import RichText from './RichText'
import LocaleContext from './LocaleContext'
import Link from './Link'

import homeHeroImage from '../images/hero/home.png'
import homeHeroGraphics from '../images/hero/home-graphics.png'
import homeHeroVideoFallback from '../images/hero/MyHR-Website-Header-Fallback.png'
import homeHeroVideoFallbackAU from '../images/hero/MyHR-Website-Header-Fallback_AU.png'

import './Hero.scss'

// Get a random video (1 of 4)
// update 6-oct-2021 to have sylvie only
// leaving others commented here in case we want them back
const videos = [
  /*{
    name: "agent"
  },
  {
    name: "ashley"
  },
  {
    name: "emma"
  },*/
  {
    name: "sylvie"
  }
];
const video = videos[Math.floor(Math.random() * videos.length)];
const homeHeroVideo = require('../images/'+video.name+'_2022_2.webm')
const homeHeroVideo2 = require('../images/'+video.name+'_bg_2022_2.mp4')
const homeHeroVideoAU = require('../images/'+video.name+'_2022_2.webm')
const homeHeroVideoAU2 = require('../images/'+video.name+'_bg_2022_2.mp4')

export default ({
  body = null,
  children = null,
  backgroundProps = {},
  variant = 'normal'
}) => {
  const locale = useContext(LocaleContext);

  const tabShowPricing = (event) => {
    event.preventDefault();
    var el = event.currentTarget;
    document.querySelectorAll(".pricing-tab").forEach(el => el.classList.remove("active"));
    el.classList.add("active")
    document.getElementById("pricing").style.display = "block";
    document.getElementById("payroll").style.display = "none";
  }

  const tabShowPayroll = (event) => {
    event.preventDefault();
    var el = event.currentTarget;
    document.querySelectorAll(".pricing-tab").forEach(el => el.classList.remove("active"));
    el.classList.add("active")
    document.getElementById("pricing").style.display = "none";
    document.getElementById("payroll").style.display = "block";
  }

  useEffect(() => {
    const handler = () => {
      const imageClass = "lifeline"+Math.floor(Math.random() * 5);
      var backgroundEl = document.getElementById("Hero__background_lifeline1");
      if(backgroundEl) {
        backgroundEl.classList.add(imageClass);
      }
    }

    if (document.readyState === "complete") {
      handler();
    } else {
      window.addEventListener('load', handler);
      return () => document.removeEventListener('load', handler);
    }
  });

  return (
  <div className={`Hero ${variant ? `Hero--${variant}` : ''}`} >
    <div
      className={locale.value == 'en-AU' ? 'Hero__background au' : 'Hero__background'}
      {...backgroundProps}
    >
      {locale.value == 'en-AU' && variant === 'home' &&
        <Container className="position-relative h-100 d-flex">
          <div className="background">
            <video width="100%" playsInline autoPlay muted loop>
                <source src={homeHeroVideoAU2} type='video/mp4; codecs="hvc1"' />
                <source src={homeHeroVideoAU} type='video/webm' />
                <img src={homeHeroVideoFallbackAU} title="MyHR Hero Banner Image" alt="Expert HR consultant and HR consultancy services MyHR" />
            </video>
          </div>
        </Container>
      }
      {locale.value != 'en-AU' && variant === 'home' &&
        <Container className="position-relative h-100 d-flex">
          <div className="background">
            <video width="100%" playsInline autoPlay muted loop>
                <source src={homeHeroVideo2} type='video/mp4; codecs="hvc1"' />
                <source src={homeHeroVideo} type='video/webm' />
                <img src={homeHeroVideoFallback} title="MyHR Hero Banner Image" alt="Expert HR consultant and HR consultancy services MyHR" />
            </video>
          </div>
        </Container>
      }
    </div>
    <div className="Hero__overlay" />
    {locale.value == 'en-AU' && variant === 'home' &&
    <Container>
      <div className="Hero__content" >
        {body && body.json &&
          <RichText json={body.json} />
        }
        {children}
      </div>
    </Container>
    }
    {(locale.value != 'en-AU' || variant != 'home') &&
    <Container>
      <div className="Hero__content" >
        {body && body.json &&
          <RichText json={body.json} />
        }
        {children}
      </div>
    </Container>
    }

    {locale.value == 'en-AU' && variant == "pricing-tabs" &&
      <Container className="pricing-tabs">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="pricing-tab nav-link active" aria-current="page" href="#" onClick={tabShowPricing}>HR</a>
          </li>
          <li class="nav-item">
            <a class="pricing-tab nav-link" href="#" onClick={tabShowPayroll}>Add Payroll</a>
          </li>
        </ul>
      </Container>}
  </div>
  )
}


export const fragment = graphql`
  fragment ContentfulHeroFragment on ContentfulHero {
    variant
    body {
      json
    }
  }
`
