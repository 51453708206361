import React, { useContext } from 'react'
import { Container, Row, Col, Button } from 'reactstrap';

import Section from './Section'
import LocaleContext from './LocaleContext'

import bannerImg from '../images/case-study/Sales_Tile_2022-04.png'
import './BookDemoSection.scss';


const BookDemoSection = (props) => {
  const locale = useContext(LocaleContext)
  const demoLink = props.bookDemoLink || locale.links.bookDemo;
  const trialLink = props.trialLink || locale.links.trial;
  const title = props.title || "See MyHR in action"

  const showBookButton = props.showBookButton !== false
  const showTrialButton = props.showTrialButton !== false
  const text = props.text || (
    <p>
      Chat with our team to see how we can help!
    </p>
  )
  const variant = props.variant || 'default' 

  const buttonStyles = {
    'book': 'electric-blue',
    'trial': 'outline-navy'
  }

  if (variant === 'compact') {
    buttonStyles.book = 'cyan';
    buttonStyles.trial = 'outline-cyan'
  }

  buttonStyles.book = props.bookButtonStyle || buttonStyles.book
  buttonStyles.trial = props.trialButtonStyle || buttonStyles.trial


  const body = (
    <div>
      <h2 className="mb-2">{title}</h2>
      <div className="my-4">{text}</div>
      
      <div>
        {showBookButton &&
          <Button
            color={buttonStyles.book}
            href={demoLink}
          >
            Book a demo
          </Button>
        }
        {showTrialButton &&
          <Button
            color={buttonStyles.trial}
            href={trialLink}
          >
            Start free trial
          </Button>
        }
        {props.extraButtons}
      </div>
    </div>
  )

  if (variant === 'compact') {
    return (
      <Section className="BookDemoSection BookDemoSectionCompact isolated-section">
        <Container>
          <Row>
            <Col lg={8} className="mx-auto text-center h-100">
              {body}
            </Col>
          </Row>
        </Container>
      </Section> 
    )
  }

  return (
    <Section className="BookDemoSection isolated-section py-0">
      <Container>
        <Row>
          <Col lg={{ size: 6, order: 12 }}
            className="h-full d-flex pt-6 pb-5 text-center text-lg-left py-lg-5"
          >
            <div className="my-auto w-100">
              {body}
            </div>
          </Col>
          <Col lg={{ size: 6, order: 1 }} className="h-full d-flex pt-lg-6 px-lg-3"
          //  className="pr-3 pr-md-3 pl-3 pl-md-6 pt-5"
           >
            <a href={demoLink} className="d-block mt-auto mx-auto">
              <img src={bannerImg} className="bannerImg" alt="Get Started with MyHR" />
            </a>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default BookDemoSection;