import React from 'react'
import { graphql } from 'gatsby'
import { Modal, ModalBody, Button } from 'reactstrap';

import './ModalVideo.scss'
/**
 * A component to show a video on click in a modal.
 * Handles different channels of embeds:
 *   - facebook
 *   - youtube
 *   - vimeo
 * 
 * Facebook iFrame example
 * ```
 *  <iframe 
 *    src="href=<video.url>width=734&show_text=false&height=413&appId" 
 *    width="734" height="413" style="border:none;overflow:hidden" 
 *    scrolling="no" frameborder="0" allowTransparency="true" 
 *    allow="encrypted-media" allowFullScreen="true"
 *  ></iframe>
 * ```
 * 
 */
class ModalVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  youtubeParams(params) {
    if (this.props.hideControls) {
      params['controls'] = 0;
    }
    else {
      params['controls'] = 1;
    }
    if (this.props.noAutoPlay) {
      params['autoplay'] = 0;
    }
    else {
      params['autoplay'] = 1;
    }
    return params;
  }

  vimeoParams(params) {
    if (this.props.noAutoPlay) {
      params['autoplay'] = false;
    }
    else {
      params['autoplay'] = true;
    }
    return params;
  }

  facebookSize() {
    return ({
      width: 734,
      height: 413
    })
  }

  facebookParams(params) {
    return {
      ...params,
      autoplay: true,
      href: this.props.url,
      show_text: false,
      appId: '',
      ...this.facebookSize()
    };
  }

  url() {
    let params = {};
    if (this.props.channel === 'facebook') {
      params = this.facebookParams(params);
      //return `https://www.facebook.com/plugins/video.php?autoplay=true&href=https%3A%2F%2Fwww.facebook.com%2FMyHRNZ%2Fvideos%2F1650550301690201%2F&width=734&show_text=false&height=413&appId
      return `https://www.facebook.com/plugins/video.php${objectToQuerystring(params)}`
    }
    if (this.props.channel === 'youtube') {
      params = this.youtubeParams(params);
    }
    else if (this.props.channel === 'vimeo') {
      params = this.vimeoParams(params);
    }
    return `${this.props.url}${objectToQuerystring(params)}`;
  }

  render() {
    // const toggle = this.toggle

    const {
      channel,
      url,
      children,
      className,
      ...rest
    } = this.props;
    return (
      <>
        {children({ toggle: this.toggle })}
        <Modal 
          external={(<button type="button" className="close" aria-hidden="true" onClick={this.toggle}></button>)}
          key="modal" 
          isOpen={this.state.modal} 
          toggle={this.toggle} 
          className={`${className} modal-video`}
        >
          <ModalBody>
            <div className="embed-responsive embed-responsive-16by9">
              {channel === 'facebook' && (
                <iframe 
                  className="embed-responsive-item" 
                  src={this.url()}
                  {...this.facebookSize()}
                  style={{border:"none", overflow:"hidden"}}
                  scrolling="no" 
                  frameBorder="0" 
                  allowTransparency="true" 
                  allow="encrypted-media" 
                  allowFullScreen
                />
              )}
              {channel !== 'facebook' && (
                <iframe 
                  className="embed-responsive-item" 
                  src={this.url()}
                  allowFullScreen
                  {...rest}
                />
              )}
            </div>
          </ModalBody>
        </Modal>
      </>
    )
  }
}

function objectToQuerystring (obj) {
  return Object.keys(obj).reduce((str, key, i) => {
    var delimiter, val;
    delimiter = (i === 0) ? '?' : '&';
    key = encodeURIComponent(key);
    val = encodeURIComponent(obj[key]);
    return [str, delimiter, key, '=', val].join('');
  }, '');
}

export default ModalVideo;


export const fragment = graphql`
  fragment Video on ContentfulVideo {
    channel
    url
  }
`