export const jsonFetch = method => url => values =>
  new Promise((resolve, reject) => {
    const fetchParams = {
      method: method,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    }
    if (!['GET', 'HEAD'].includes(method)) {
      fetchParams.body = values ? JSON.stringify(values) : undefined
    }
    fetch(url, fetchParams)
      .then(response => response.json())
      .then(resolve)
      .catch(reject)
  })

export default jsonFetch
