import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import { Button as RSButton } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faPlay } from '@fortawesome/free-solid-svg-icons'

import LocaleContext from './LocaleContext'
import ModalVideo from './ModalVideo'


export default ({
  type = null,
  href = null,
  localePrefix = false,
  video = null,
  children = null,
  color = null,
  ...props
}) => {
  const locale = useContext(LocaleContext);

  const linkHref = (href && localePrefix)
    ? locale.prefix + href
    : href

  const LinkComponent = (linkProps) => {
    if (type === 'button')
      return <RSButton {...linkProps} />

    else if (video)
      return <span {...linkProps}/>

    return <a {...linkProps} />
  }

  if (video)
    return (
      <ModalVideo {...video}>
        {({ toggle }) => 
          <LinkComponent
            {...props}
            className={`px-3 px-md-4 ${props.className ? ` ${props.className}` : ''}`}
            onClick={toggle}
          >
            <FontAwesomeIcon icon={faPlay} className="mr-2 ml-0" />
            {children ? children : 'Play Video'}
          </LinkComponent>
        }
      </ModalVideo>
    )
    
  if (color == 'storylane' || (href && href.includes("storylane")))
    return (
      <LinkComponent
        href={linkHref}
        target="_blank"
        color={color}
        children={children}
        className={`px-3 px-md-4 btn storylane ${props.className ? ` ${props.className}` : ''}`}
        {...props}
      >
        <FontAwesomeIcon icon={faPlay} className="mr-2 ml-0" />
        {children ? children : 'Take software tour'}
      </LinkComponent>
    )

    if (href && href.startsWith('tel:')) {
      return (
        <LinkComponent
          href={linkHref}
          color={color}
          className={`btn-tel ${props.className ? props.className : ''}`}
          {...props}
        >
          <FontAwesomeIcon icon={faPhone} className="mr-2 ml-0" style={{ transform: "rotate(90deg)" }}/>
          {children}
        </LinkComponent>
      )
    }

  return (
    <LinkComponent
      href={linkHref}
      color={color}
      children={children}
      {...props}
    />
  )
}


export const addLocalePrefix = (path) => {
  const locale = useContext(LocaleContext);
  return locale.prefix + path
}


export const fragment = graphql`
  fragment ContentfulLinkFragment on ContentfulLink {
    label
    type
    href
    localePrefix
    color
    video {
      url
      channel
    }
  }
`
