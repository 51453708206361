import React, { useState, useContext } from 'react'
import { Form, Input, Button } from 'reactstrap'
import { submit as hubspotSubmit } from '../utils/hubspotForm'
import LocaleContext from './LocaleContext'

import './SubscribeForm.scss'

const HUBSPOT_SETTINGS = {
  ['en-NZ']: {
    portalId: '3837251',
    formId: '98d40006-d2b4-4da5-8bca-3856fbe49760',
    skipValidation: true,
  },
  ['en-AU']: {
    portalId: '3837251',
    formId: '05872014-43fa-4b16-b54f-5d0f803dbd20',
    skipValidation: true,
  },
}

const SubscribeForm = ({
  themeColor = 'electric-blue',
  textColor = null,
  buttonLabel = 'Subscribe',
  successMessage = <p>Thanks for subscribing!</p>,
  children,
}) => {
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState(false)
  const locale = useContext(LocaleContext)
  const settings = HUBSPOT_SETTINGS[locale.value]

  /**
   * Submit
   *
   */
  const submit = event => {
    event.preventDefault()
    setSubmitting(true)

    hubspotSubmit({
      ...settings,
      fields: [
        {
          name: 'email',
          value: email,
        },
        // ensure lifecycle stage of subscriber rather than default lead
        {
          name: 'lifecyclestage',
          value: 'subscriber',
        },
        {
          name: 'billing_location_country_iso',
          value: locale.value === 'en-AU' ? 'AU' : 'NZ',
        },
      ],
    })
      .then(({ status, errors }) => {
        console.log(status, errors)
        if (status && status === 'error') setErrors(errors)
        else setSubmitted(true)
      })
      .catch(e => {
        console.error('handleSumbmit: error: ', e)
        setSubmitted(false)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  if (submitted) return successMessage

  return (
    <Form className="SubscribeForm" onSubmit={submit}>
      <div className="d-flex">
        <label className="SubscribeForm__label mr-3">
          Email address
          <Input
            type="email"
            className={`SubscribeForm__input px-1 pb-1 border-${themeColor} ${
              textColor ? `text-${textColor}` : ''
            }`}
            placeholder="Your email address"
            aria-label="Your email address"
            onChange={event => setEmail(event.target.value)}
            required
          />
        </label>
        <Button
          className="SubscribeForm__button"
          color={themeColor}
          disabled={submitting}
        >
          {submitting ? 'Saving...' : buttonLabel}
        </Button>
      </div>
      {children}
      {errors && (
        <div className="SubscribeForm__error">
          Invalid email did you make a mistake?
        </div>
      )}
    </Form>
  )
}

export default SubscribeForm
