import React from 'react'

const LOCALES = {
  'en-NZ': {
    systemLocale: 'en_NZ',
    value: 'en-NZ',
    country: 'New Zealand',
    countryCode: 'NZ',
    countryCodeLower: 'nz',
    prefix: '/nz',
    currency: 'NZD',
    links: {
      bookDemo: '/nz/book-a-demo',
      trial: '/nz/trial'
    }
  },
  'en-AU': {
    systemLocale: 'en_AU',
    value: 'en-AU',
    country: 'Australia',
    countryCode: 'AU',
    countryCodeLower: 'au',
    prefix: '/au',
    currency: 'AUD',
    links: {
      bookDemo: '/au/book-a-demo',
      trial: '/au/trial'
    }
  },
  'en-CA': {
    systemLocale: 'en_CA',
    value: 'en-CA',
    country: 'Canada',
    countryCode: 'CA',
    countryCodeLower: 'ca',
    prefix: '/ca',
    currency: 'CAD',
    links: {
      bookDemo: null,
      trial: null
    }
  },
}


export const getLocale = (value) => {
  if (LOCALES[value])
    return LOCALES[value]
  
  return LOCALES['en-NZ']
}


export default React.createContext(LOCALES['en-NZ']);
