import jsonFetch from './jsonFetch'

const hubspotutk = () =>
  document.cookie.replace(
    /(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/,
    '$1'
  )
export const pageUri = () =>
  (window && window.location && window.location.href) || 'Unknown'
const pageTitle = () => document.title || 'Unknown'
const nowTimestamp = () => new Date().getTime()

export const url = ({ portalId, formId }) =>
  `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`

export const submit = ({ portalId, formId, fields, skipValidation }) => {
  return new Promise((resolve, reject) => {
    jsonFetch('POST')(url({ portalId, formId }))(
      requestBody({ fields, skipValidation })
    )
      .then(result => {
        return resolve(result)
      })
      .catch(err => {
        return reject(err)
      })
  })
}

export const requestBody = ({ fields, skipValidation = false }) => ({
  submittedAt: nowTimestamp(),
  fields,
  context: {
    hutk: hubspotutk() || undefined,
    pageUri: pageUri(),
    pageName: pageTitle(),
  },
  skipValidation,
})

export default {
  url,
  requestBody,
  pageUri,
}
