import React from 'react'
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Link from './Link'


const richTextOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { title, description, file } = node.data.target.fields;
      const mimeType = file['en-NZ'].contentType
      const mimeGroup = mimeType.split('/')[0]

      if (mimeGroup === 'image')
        return <img
          title={ title ? title['en-NZ'] : null}
          alt={description ?  description['en-NZ'] : null}
          src={file['en-NZ'].url}
        />

      return null
    },
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      if (node.data.target.sys && node.data.target.sys.contentType.sys && node.data.target.sys.contentType.sys.id === 'link') {
        let { label, type, href, localePrefix, video } = node.data.target.fields
        label = label && label['en-NZ']
        localePrefix = localePrefix && localePrefix['en-NZ']
        href = href && href['en-NZ']
        type = type && type['en-NZ']
        video = video && video['en-NZ'] && video['en-NZ'].fields && ({
          url: video['en-NZ'].fields.url && video['en-NZ'].fields.url['en-NZ'],
          channel: video['en-NZ'].fields.channel && video['en-NZ'].fields.channel['en-NZ']
        })

        return (
          <Link
            type={type}
            localePrefix={localePrefix}
            href={href}
            video={video}
            color={video ? 'electric-blue' : 'gray'}
          >
            {label}
          </Link>
        )
      }

      return null
    }
  },
  renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text])
}


export default ({ json, options = richTextOptions }) =>
  documentToReactComponents(json, options)
