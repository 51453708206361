import React, { useState, useEffect, useContext } from 'react'
import Sticky from 'react-stickynode'
import {
  Navbar as NavbarRS,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes, faArrowRight } from '@fortawesome/free-solid-svg-icons'

import Link, { addLocalePrefix } from './Link'
import Logo, { LogoWhite } from './Logo'
import NAVIGATION from '../data/navigation'
import LocaleContext from './LocaleContext'

import earthIcon from '../images/icons/earth.svg'

import './Navbar.scss'

/**
 * Small components
 * 
 */
const FreeTrialButton = ({ className='' }) => (
  <Link
    href="/trial"
    localePrefix={true}
    type="button"
    color="cyan"
    size="sm"
    className={`btn-free-trial ${className}`}
  >
    Free trial
  </Link>
)


const LoginButton = ({ className }) => (
  <Link
    type="button"
    href="https://app.myhr.works/login"
    localePrefix={false}
    size="sm"
    color="gray"
    className={`btn-login ${className}`}
  >
    Log in
  </Link>
)


const Toggler = ({ onClick, collapsed }) => (
  <div className="Navbar__toggler my-auto mr-0 ml-1 d-lg-none" onClick={onClick} >
    <FontAwesomeIcon
      icon={collapsed ? faBars : faTimes}
      color="white"
    />
  </div>
)

const LocaleSwitcher = ({ className }) => {
  const locale = useContext(LocaleContext);

  return (
    <div
      className={`Navbar__localeIcon ${className}`}
    >
      <div className="Navbar__localeIcon__button">
        <img
          className="Navbar__localeIcon__earth my-auto mr-1"
          src={earthIcon}
          alt="Earth Icon"
        />
        <span className="my-auto">{locale.countryCode}</span>

      </div>
      
      <div className="Navbar__localeIcon__options">
        <ul className="list-unstyled">
          <li><a href="/nz">NZ</a></li>
          <li><a href="/au">AU</a></li>
          <li><a href="/ca">CA</a></li>
        </ul>
      </div>

    </div>
  )
}


const NavbarLogo = (props) => (
  <NavbarBrand {...props} aria-label="MyHR Logo">
    <Logo/>
    <LogoWhite/>
  </NavbarBrand>
)


/**
 * Main component
 * 
 */
export default () => {
  const [ collapsed, setCollapsed ] = useState(true)
  const [ sticky, setSticky ] = useState(false)
  const toggle = () => setCollapsed(!collapsed)
  const locale = useContext(LocaleContext)
  const navLinks = NAVIGATION[locale.value] || []

  useEffect(() => {
    if (window.scrollY > 50)
      setSticky(true)
  }, [])


  const CustomNavDropdown = ({ navGroup }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        nav={true}
      >
        <DropdownToggle
          nav
          caret
          className="nav-link"
        >
          {navGroup.label}
        </DropdownToggle>
        <DropdownMenu right className={navGroup.label}>
          {navGroup.desc &&<div className="mega-left">
            <span className="mega-left-title">{navGroup.subLabel}</span>
            {navGroup.desc && <p>{navGroup.desc}</p>}
            {navGroup.href &&
            <Link
              type="button"
              href={navGroup.href}
              localePrefix={navGroup.localePrefix}
              color="electric-blue"
            >
              {navGroup.buttonLabel}
            </Link>}
          </div>}

          <div className={'mega-right '+navGroup.label.toLowerCase()}>
            {/* navGroup.label=="Platform" &&
              <div className="mega-nav-item d-block d-sm-none" key={0}>
              <DropdownItem
                href={addLocalePrefix('/platform')}
                key={0}
                toggle={true}
                onClick={() => {
                  setCollapsed(true)
                }}
              >
                Platform Overview
              </DropdownItem></div>
            */}

            {navGroup.children.map((navItem, index) => {

              if(navItem.excludeNavbar) return;

              const href = (navItem.localePrefix)
                ? addLocalePrefix(navItem.href)
                : navItem.href

              return (<div className="mega-nav-item" key={index}>
                <a href={(navItem.localePrefix) ? addLocalePrefix(navItem.href) : navItem.href} className="noStyle">{navItem.image && <img src={navItem.image} />}
                <DropdownItem
                  href={href}
                  key={index}
                  toggle={true}
                  onClick={() => {
                    setCollapsed(true)
                  }}
                >
                  {navItem.label}
                </DropdownItem>
                <div className="mega-nav-item-desc">
                  {locale.value=="en-NZ" && 
                  <p>{navItem.desc}</p>}
                  {locale.value=="en-AU" && 
                  <p>{navItem.descAu ? navItem.descAu : navItem.desc}</p>}
                
                  {(navItem.href) ? <a href={(navItem.localePrefix) ? addLocalePrefix(navItem.href) : navItem.href}>Learn more&nbsp; <FontAwesomeIcon icon={faArrowRight} /></a> : null}
                </div></a></div>
              )
            })}
          </div>
        </DropdownMenu>
      </Dropdown>
    )
  }

  return (
    <>
      <Sticky onStateChange={({ status }) =>
        setSticky((status === 2) ? true : false)
      }/>
      <NavbarRS
        className="Navbar"
        expand="lg"
      >
        <div className={`Navbar__body ${sticky ? 'sticky' : '' }`}>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css" integrity="sha512-iecdLmaskl7CVkqkXNQ/ZH/XLlvWZOJyj7Yy7tcenmpD1ypASozpmT/E0iPtmFIB46ZmdtAc9eNBvH0H/ZpiBw==" crossorigin="anonymous" referrerpolicy="no-referrer" />
          <div className="h-100 position-relative px-lg-5 nav-container d-lg-flex">
            <div className="Navbar__main">
              <NavbarLogo
                href={addLocalePrefix('/')}
                className="ml-0 mr-auto"
              />
              <Toggler onClick={toggle} collapsed={collapsed} />
            </div>

            <div className={`Navbar__navigation ${collapsed ? 'd-none' : 'd-block'} d-lg-block`}>
              <Nav navbar>
                {navLinks.map((navGroup, index) => {

                  if (navGroup.label === 'Pricing')
                    return (
                      <NavItem key={index}>
                        <NavLink href={addLocalePrefix('/pricing')}>Pricing</NavLink>
                      </NavItem>
                    )

                  if (navGroup.label === 'Contact')
                    return (
                      <NavItem key={index}>
                        <NavLink href={addLocalePrefix('/contact')}>Contact</NavLink>
                      </NavItem>
                    )

                  if (navGroup.label === 'Customer Stories')
                      return (
                        <NavItem key={index}>
                          <NavLink href={addLocalePrefix('/customer-stories')}>Customer Stories</NavLink>
                        </NavItem>
                      )

                  return <CustomNavDropdown navGroup={navGroup} key={index} />
                })}
              </Nav>
              
              <FreeTrialButton className="my-3 d-lg-none px-4 w-100" />
              <LoginButton className="my-3 d-lg-none px-4 w-100" />
              <div className="mx-auto text-center">
                <LocaleSwitcher className="my-3 d-inline-flex d-lg-none"/>
              </div>
            </div>

            <div className="d-none d-lg-flex">
              <LocaleSwitcher className="my-auto mx-2"/>
              <FreeTrialButton className="my-auto mx-2"/>
              <LoginButton className="my-auto ml-2 mr-0"/>
            </div>
          </div>
        </div>
      </NavbarRS>
    </>
  )
}
