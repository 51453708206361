export const bookADemoLinkSwitch = (locale) => {
  const defaultLocale = 'en-NZ'

  const demoLinkByLocale = {
    'en-NZ': 'https://myhr.works/nz/book-a-demo',
    'en-AU': 'https://myhr.works/au/book-a-demo'
  }

  return demoLinkByLocale[locale] || demoLinkByLocale[defaultLocale]

}