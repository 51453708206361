import React from 'react'

export const LogoWhite = () =>
  <svg className="logo-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 231 90" height="90" width="231">
    <title>MyHR</title>
    <g fill="#FFFFFF">
      <polygon points="167.23 36.27 149.62 36.27 149.62 16.43 136.84 16.43 136.84 69.68 149.62 69.68 149.62 46.67 167.23 46.67 167.23 69.68 180.01 69.68 180.01 16.43 167.23 16.43 167.23 36.27"/>
      <path d="m218.88,47.82c5.18-2.56,9.26-7.43,9.26-15.26,0-10.06-7.03-16.14-18.92-16.14h-23.03v53.26h12.61v-19.39h7.47l8.92,19.27h15.19l-11.5-21.74Zm-9.98-7.75h-10.1v-12.23h10.1c3.35,0,6.23,1.6,6.23,5.68s-2.8,6.55-6.23,6.55Z"/>
      <path d="m129.36,15.76h0c-1.68-1.06-4.62-1.69-7.86-1.69-1.04,0-1.99.07-2.76.19l-.63.1v.64c-.02,2.91-.64,10.37-1.69,20.46-1.1,10.55-4.42,26.42-8.02,26.98-.98.15-1.56-1.23-1.56-1.23-1.49-4.21-1.85-18.56-.98-39.35.14-3.41-1.04-4.99-4.18-5.63-.83-.2-1.78-.3-2.81-.3-1.94,0-3.85.36-4.87.72l-.42.15-.07.44c-5.7,35.03-10.78,43.64-11.55,44.8-.27-4.36-1.55-31.64-1.68-49.25,0-1.08-.42-1.96-1.25-2.69-2.18-1.9-6.54-2.18-8.94-2.18h-.96s-.21.41-.21.41c-6.67,12.9-11.35,28.02-13.53,35.8-.27-12.85-.87-39.89-1.14-40.94-.09-.28-1.02-2.75-7.09-2.75-1.54,0-3.13.12-4.71.36l-.3.05-.18.24c-2.49,3.24-9.59,19.11-14.18,41.19-.23-9.27-.7-24.52-1.32-27.01l-.05-.2-.15-.15c-4.17-4.17-6.63-4.35-7.48-4.24-2.61.3-14.77,7.66-18.09,20.63l-.02.13c-.08,1.11-.05,1.95.95,3.46,2.46,3.69,7.27,4.31,7.47,4.34l.64.08.18-.62c1.4-4.95,4.91-10.08,6.73-12.52.12,1.67.28,4.36.46,7.31.69,11.44,1.84,30.6,3.38,40.58.08.28.41,1.25,1.31,1.75,1.75.96,4.64.92,8.34-.13l.46-.13.07-.48c.91-5.83,5.08-27.4,12-49.25l.97-3.07c.81,11.75,3.14,45.29,3.33,47.05l.02.16.08.14c.31.51,1.62,2.18,5.78,2.18h.27c1.16.05,4.26.15,5.04-3.42,2.29-10.42,10.24-36.36,10.31-36.6l.06-.2c.18,6.44.65,18.78,1.88,30.5.25,2.42,2.3,8.7,8.67,8.74h.03c1.52,0,3.78-.8,5.61-3.04,2.26-2.77,4.12-6.69,6.87-14.49l1.17-4.01c.15,3.06.47,6.94,1.1,9.6,1.11,4.63,3.55,10.51,8.9,12.26.82.27,1.65.4,2.46.4,8.87,0,14.01-15.66,14.23-16.33h0s-.09,3.54-.09,3.54c0,.29-.19,7.07-1.58,14.21l-.03.11c-.89,3.58-3.11,9.52-7.77,9.08l-.97-.09.16.96c.02.13.54,3.15,3.29,4.82,1.53.92,3.05,1.23,4.41,1.23,2.51,0,4.44-1.06,4.73-1.23,5.3-2.77,7.1-13.45,7.41-15.57.74-5.15,2.44-20.75,2.11-54.25-.01-1.11-.58-2.01-1.74-2.74Z"/>
    </g>
  </svg>


export default () =>
  <svg className="logo-default" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 231 90" height="90" width="231">
    <title>MyHR</title>
    <g>
      <polygon points="167.23 36.27 149.62 36.27 149.62 16.43 136.84 16.43 136.84 69.68 149.62 69.68 149.62 46.67 167.23 46.67 167.23 69.68 180.01 69.68 180.01 16.43 167.23 16.43 167.23 36.27"/>
      <path d="m218.88,47.82c5.18-2.56,9.26-7.43,9.26-15.26,0-10.06-7.03-16.14-18.92-16.14h-23.03v53.26h12.61v-19.39h7.47l8.92,19.27h15.19l-11.5-21.74Zm-9.98-7.75h-10.1v-12.23h10.1c3.35,0,6.23,1.6,6.23,5.68s-2.8,6.55-6.23,6.55Z"/>
      <path d="m129.36,15.76h0c-1.68-1.06-4.62-1.69-7.86-1.69-1.04,0-1.99.07-2.76.19l-.63.1v.64c-.02,2.91-.64,10.37-1.69,20.46-1.1,10.55-4.42,26.42-8.02,26.98-.98.15-1.56-1.23-1.56-1.23-1.49-4.21-1.85-18.56-.98-39.35.14-3.41-1.04-4.99-4.18-5.63-.83-.2-1.78-.3-2.81-.3-1.94,0-3.85.36-4.87.72l-.42.15-.07.44c-5.7,35.03-10.78,43.64-11.55,44.8-.27-4.36-1.55-31.64-1.68-49.25,0-1.08-.42-1.96-1.25-2.69-2.18-1.9-6.54-2.18-8.94-2.18h-.96s-.21.41-.21.41c-6.67,12.9-11.35,28.02-13.53,35.8-.27-12.85-.87-39.89-1.14-40.94-.09-.28-1.02-2.75-7.09-2.75-1.54,0-3.13.12-4.71.36l-.3.05-.18.24c-2.49,3.24-9.59,19.11-14.18,41.19-.23-9.27-.7-24.52-1.32-27.01l-.05-.2-.15-.15c-4.17-4.17-6.63-4.35-7.48-4.24-2.61.3-14.77,7.66-18.09,20.63l-.02.13c-.08,1.11-.05,1.95.95,3.46,2.46,3.69,7.27,4.31,7.47,4.34l.64.08.18-.62c1.4-4.95,4.91-10.08,6.73-12.52.12,1.67.28,4.36.46,7.31.69,11.44,1.84,30.6,3.38,40.58.08.28.41,1.25,1.31,1.75,1.75.96,4.64.92,8.34-.13l.46-.13.07-.48c.91-5.83,5.08-27.4,12-49.25l.97-3.07c.81,11.75,3.14,45.29,3.33,47.05l.02.16.08.14c.31.51,1.62,2.18,5.78,2.18h.27c1.16.05,4.26.15,5.04-3.42,2.29-10.42,10.24-36.36,10.31-36.6l.06-.2c.18,6.44.65,18.78,1.88,30.5.25,2.42,2.3,8.7,8.67,8.74h.03c1.52,0,3.78-.8,5.61-3.04,2.26-2.77,4.12-6.69,6.87-14.49l1.17-4.01c.15,3.06.47,6.94,1.1,9.6,1.11,4.63,3.55,10.51,8.9,12.26.82.27,1.65.4,2.46.4,8.87,0,14.01-15.66,14.23-16.33h0s-.09,3.54-.09,3.54c0,.29-.19,7.07-1.58,14.21l-.03.11c-.89,3.58-3.11,9.52-7.77,9.08l-.97-.09.16.96c.02.13.54,3.15,3.29,4.82,1.53.92,3.05,1.23,4.41,1.23,2.51,0,4.44-1.06,4.73-1.23,5.3-2.77,7.1-13.45,7.41-15.57.74-5.15,2.44-20.75,2.11-54.25-.01-1.11-.58-2.01-1.74-2.74Z"/>
    </g>
  </svg>
